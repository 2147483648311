
.StdButton {
    transition: 300ms linear 100ms;
    color: #ffffff;
    background-color: #0055A2;
}

.StdButton:hover {
    background-color: #5AB0FF;
}

.StdButton:active {
    background-color: #0055A2;
    transition: 50ms linear;
}

.StdButtonInverted {
    transition: 300ms linear 100ms;
    color: #0055A2;
    background-color: #ffffff;
}

.StdButtonInverted:hover {
    background-color: #6AC0FF;
}

.StdButtonInverted:active {
    background-color: #3184FF;
    transition: 5ms linear;
}
