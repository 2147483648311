.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  background-color: #ffffff;
  flex-direction: column;
}


.StdInput {
}

.StdInput::placeholder {
  color: #909090;
  font-weight: normal;
}
